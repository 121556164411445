<template>
  <div class="login_container">

    <div class="login_box">
      <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
        <el-form-item label="用户名" prop="username">
          <el-input
            type="text"
            v-model.trim="loginForm.username"
            autocomplete="off"
            placeholder="请输入账号"
            @keyup.enter.native="handleInputConfirm()"
          >
            <i slot="prefix" class="el-icon-user-solid icon_title"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model.trim="loginForm.password"
            autocomplete="off"
            placeholder="请输入密码"
            @keyup.enter.native="handleInputConfirm()"
          >
            <i slot="prefix" class="el-icon-unlock icon_title"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" class="VerCode" prop="VerCode" inline>
          <el-input
            v-model="loginForm.VerCode"
            autocomplete="off"
            maxlength="4"
            placeholder="请输入验证码"
            @keyup.enter.native="handleInputConfirm()"
          >
            <i slot="prefix" class="el-icon-mobile-phone icon_title"></i>
          </el-input>
          <img ref="imgRefs" alt="" @click="onImage" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onLogin">登录</el-button>
          <el-button @click="onreSet('loginFormRef')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { findColumnListByUser } from "../../api/column";
import sha1 from "sha1";
import { userLogin, vCode, userCode } from "../../api/login";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        VerCode: "",
      },
      loginFormRules: {
        username: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        VerCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {
    this.onImage();
   
   
  },
  computed: {
    ...mapState["columnlist"],
  },
  methods: {
  

    onreSet(formName) {
      this.$refs[formName].resetFields();
      this.loginForm.VerCode = "";
    },
    handleInputConfirm() {
      this.onLogin();
    },
    async onLogin() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        var formData = {
          username: this.loginForm.username,
          password: sha1(this.loginForm.password),
          verifyCode: this.loginForm.VerCode,
        };
        const { data } = await userLogin(formData);
        if (data.code !== 0) {
          this.onImage();
          return this.$message.error(data.msg);
        } else if (data.code === 0) {
          const { data } = await findColumnListByUser();
          this.$store.commit("setColumnList", data.data);
          this.$message({
            showClose: true,
            message: "登录成功",
            type: "success",
          });
          this.$router.push("/index");
        }
      });
    },
    onImage() {
      this.$refs.imgRefs.src = userCode + "?" + Math.random();
    },
  },
};
</script>
<style lang="less" scoped>
.login_container {
  
  height: 100%;
  background-image: url("./03.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .login_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 300px;
    background-color: rgba(255, 255, 255);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .el-form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    /deep/.el-form-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .el-form-item__content {
        .el-input {
          width: 350px;
        }
        .icon_title {
          font-size: 17px;
        }
      }
      .el-form-item__label {
        width: 80px !important;
        text-align: center;
      }
    }
    /deep/.VerCode {
      .el-form-item__content {
        display: flex;
        justify-content: center;
        align-items: center;
        .el-input {
          display: inline-block;
          width: 175px;
        }
        img {
          display: inline-block;
          width: 175px;
          height: 40px;
        }
      }
    }
  }
}
</style>